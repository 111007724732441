import React, { useEffect } from "react";
import { useState, useCallback, useRef, useMemo } from "react";
import CourseSelectDropDown from "../CourseSelectDropDown/CourseSelectDropDown";
import { getSubscribedUsers, getExpiredUsers, getInactiveUsers } from "./StudentDirectoryClient";
import DropDown from "./StudentDirectoryDropDown";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./StudentDirectoryTable.css";
import Header from "../Header/Header";
import { getMenuItems } from "../menuItems/AdminDashboardMenu";
import { TABLES } from "../../common/constants";
import { ExportToCsv } from "export-to-csv";
import RemarksButton from "./StudentRemarksButton";
import Button from "@mui/material/Button";
import { Paper, makeStyles } from "@material-ui/core";
import mailIcon from "../../resources/images/mailIcon.svg";
import downloadIcon from "../../resources/images/download.svg";
import Loader from "../Loader/loader";
import { useGlobalContext } from "../../context/GlobalContext";
const useStyles = makeStyles((theme) => ({
    studentDirectoryWrapper: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
    },
    header: {
        margin: "-1vw",
    },
}));

export default function StudentDirectory() {
    const classes = useStyles();

    const gridRef = useRef();
    const { courseId, pageLoader, setPageLoader } = useGlobalContext();
    const [subscribedUsers, setSubscribedUsers] = useState();
    const [table, setTable] = useState(TABLES.SUBSCRIBED);
    const [expiredUsers, setExpiredUsers] = useState();
    const [inactiveUsers, setInactiveUsers] = useState();
    const [row, setRow] = useState();
    const DROPDOWN = {
        BATCH: "BATCH",
        PLACEMENT: "PLACEMENT",
    };
    useEffect(() => {
        setPageLoader(true);
        setSubscribedUsers(null);
        setExpiredUsers(null);
        setInactiveUsers(null);

        const subscribedUsersPromise = getSubscribedUsers(courseId, setSubscribedUsers);
        const expiredUsersPromise = getExpiredUsers(courseId, setExpiredUsers);
        const inactiveUsersPromise = getInactiveUsers(courseId, setInactiveUsers);

        Promise.all([subscribedUsersPromise, expiredUsersPromise, inactiveUsersPromise]).then(
            (values) => {
                setPageLoader(false);
            }
        );
    }, [courseId]);

    const DefaultColDef = {
        editable: true,
        sortable: true,
        floatingFilter: true,
        filter: true,
        unSortIcon: true,
        filterParams: {
            debounceMs: 0,
            buttons: ["clear"],
        },
    };
    const dateFilterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("-");
            var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
        },
        browserDatePicker: true,
        minValidYear: 2000,
        maxValidYear: 2025,
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    };
    const [subscribedUsersColumnDefs] = useState([
        {
            field: "Name",
            pinned: "left",
            cellStyle: { color: "#444BAB", textAlign: "justify", fontFamily: "Lato-Regular" },
            tooltipField: "Name",
        },
        {
            field: "EmailID",
            pinned: "left",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            tooltipField: "EmailID",
        },
        {
            field: "PhoneNumber",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "Batch",
            ediatble: true,
            cellRenderer: (params) => <DropDown type={DROPDOWN.BATCH} params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "ExhaustedSubscribedDays",
            headerTooltip: "Exhausted Subscribed Days",
            width: "300",
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "TotalProblemsSolved",
            headerTooltip: "Total Problems Solved",
            width: "250",
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "OverallPointsScored",
            filter: "agNumberColumnFilter",
            width: "250",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "ThirtyDaysPoints",
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "TechStack",
            cellStyle: { fontFamily: "Lato-Regular" },
            tooltipField: "TechStack",
        },
        {
            field: "Languages",
            cellStyle: { fontFamily: "Lato-Regular" },
            tooltipField: "Languages",
        },
        {
            field: "Profession",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "Experience",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "PlacementStatus",
            ediatble: true,
            width: 300,
            cellRenderer: (params) => <DropDown type={DROPDOWN.PLACEMENT} params={params} />,
        },
        {
            field: "ActivityStatus",
            cellStyle: (params) => {
                if (params.value === "Active") {
                    return { color: "#1F9C7D", fontFamily: "Lato-Regular" };
                } else if (params.value === "Inactive") {
                    return { color: "#FF3D3D", fontFamily: "Lato-Regular" };
                }
            },
        },
        {
            field: "DaysLeftSubscription",
            headerTooltip: "Days Left Subscription",
            filter: "agNumberColumnFilter",
            width: "250",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "SubscriptionDuration",
            filter: "agNumberColumnFilter",
            width: "250",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "Remarks",
            editable: true,
            width: 250,
            cellRenderer: (params) => <RemarksButton params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
            tooltipField: "Remarks",
        },
    ]);

    const [expiredUsersColumnDefs] = useState([
        {
            field: "Name",
            pinned: "left",
            cellStyle: { color: "#444BAB", textAlign: "justify", fontFamily: "Lato-Regular" },
            tooltipField: "Name",
        },
        {
            field: "EmailID",
            pinned: "left",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            tooltipField: "EmailID",
        },
        { field: "PhoneNumber" },
        {
            field: "Batch",
            ediatble: true,
            cellRenderer: (params) => <DropDown type={DROPDOWN.BATCH} params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "SubsEndedDays",
            headerName: "Subscription Ended Days",
            filter: "agNumberColumnFilter",
            width: 300,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "SubscriptionDate",
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "ExpiredDate",
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "SubscriptionDuration",
            filter: "agNumberColumnFilter",
            width: 250,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "WatchTime",
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "TotalProblemsSolved",
            filter: "agNumberColumnFilter",
            width: 250,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "OverallPointsScored",
            filter: "agNumberColumnFilter",
            width: 250,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "ActivityStatus",
            cellStyle: (params) => {
                if (params.value === "Active") {
                    return { color: "#1F9C7D", fontFamily: "Lato-Regular" };
                } else if (params.value === "Inactive") {
                    return { color: "#FF3D3D", fontFamily: "Lato-Regular" };
                }
            },
        },
        {
            field: "PlacementStatus",
            ediatble: true,
            width: 300,
            cellRenderer: (params) => <DropDown type={DROPDOWN.PLACEMENT} params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "Remarks",
            editable: true,
            width: 250,
            cellRenderer: (params) => <RemarksButton params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
            tooltipField: "Remarks",
        },
    ]);

    const [inactiveUsersColumnDefs] = useState([
        {
            field: "Name",
            pinned: "left",
            cellStyle: { color: "#444BAB" },
            cellStyle: { color: "#444BAB", textAlign: "justify", fontFamily: "Lato-Regular" },
            tooltipField: "Name",
        },
        {
            field: "EmailID",
            pinned: "left",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            tooltipField: "EmailID",
        },
        {
            field: "PhoneNumber",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "Batch",
            ediatble: true,
            cellRenderer: (params) => <DropDown type={DROPDOWN.BATCH} params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "LastTopicName",
            headerName: "Last Topic Studied",
            cellStyle: { fontFamily: "Lato-Regular" },
            tooltipField: "LastTopicStudied",
        },
        {
            field: "ActiveDays",
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "ExhaustedSubscribedDays",
            headerTooltip: "Exhausted Subscribed Days",
            width: 300,
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "WatchTime",
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "TotalProblemsSolved",
            width: 250,
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "OverallPointsScored",
            width: 250,
            filter: "agNumberColumnFilter",
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "PlacementStatus",
            ediatble: true,
            width: 300,
            cellRenderer: (params) => <DropDown type={DROPDOWN.PLACEMENT} params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "DaysLeftSubscription",
            filter: "agNumberColumnFilter",
            width: 250,
            cellStyle: { fontFamily: "Lato-Regular" },
        },
        {
            field: "Remarks",
            editable: true,
            width: 250,
            cellRenderer: (params) => <RemarksButton params={params} />,
            cellStyle: { fontFamily: "Lato-Regular" },
            tooltipField: "Remarks",
        },
    ]);

    const onGridReady = (params) => {
        if (table === TABLES.SUBSCRIBED) {
            if (!subscribedUsers) params.api.showLoadingOverlay();
            setRow(params.api.getDisplayedRowCount());
        } else if (table === TABLES.EXPIRED) {
            if (!expiredUsers) params.api.showLoadingOverlay();
            setRow(params.api.getDisplayedRowCount());
        } else if (table === TABLES.INACTIVE) {
            if (!inactiveUsers) params.api.showLoadingOverlay();
            setRow(params.api.getDisplayedRowCount());
        }
    };
    const onRowDataUpdated = (params) => {
        setRow(params.api.getDisplayedRowCount());
    };
    const onFilterChanged = (params) => {
        let rowData = [];
        gridRef.current.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
        setRow(rowData.length);
    };
    const getSubscribedUsersGrid = () => {
        return (
            <div className="table-container">
                <div
                    className="ag-theme-alpine"
                    style={{
                        width: "100%",
                        height: 550,
                        textAlign: "center",
                        "--ag-borders": "none",
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        enableFilter={true}
                        rowData={subscribedUsers}
                        onGridReady={onGridReady}
                        onRowDataUpdated={onRowDataUpdated}
                        defaultColDef={DefaultColDef}
                        columnDefs={subscribedUsersColumnDefs}
                        onFilterChanged={onFilterChanged}
                        tooltipShowDelay={0}
                    ></AgGridReact>
                </div>
            </div>
        );
    };
    const getExpiredUsersGrid = () => {
        return (
            <div className="table-container">
                <div
                    className="ag-theme-alpine"
                    style={{
                        width: "100%",
                        height: 550,
                        textAlign: "center",
                        "--ag-borders": "none",
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        enableFilter={true}
                        rowData={expiredUsers}
                        onGridReady={onGridReady}
                        defaultColDef={DefaultColDef}
                        columnDefs={expiredUsersColumnDefs}
                        onFilterChanged={onFilterChanged}
                        tooltipShowDelay={0}
                    ></AgGridReact>
                </div>
            </div>
        );
    };
    const getInactiveUsersGrid = () => {
        return (
            <div className="table-container">
                <div
                    className="ag-theme-alpine"
                    style={{
                        width: "100%",
                        height: 550,
                        textAlign: "center",
                        "--ag-borders": "none",
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        enableFilter={true}
                        rowData={inactiveUsers}
                        onGridReady={onGridReady}
                        defaultColDef={DefaultColDef}
                        columnDefs={inactiveUsersColumnDefs}
                        onFilterChanged={onFilterChanged}
                        tooltipShowDelay={0}
                    ></AgGridReact>
                </div>
            </div>
        );
    };

    const getTableHeaderNames = (tableType, tableName) => {
        return (
            <div
                className={
                    table && table === tableType ? "table-heading-blue" : "table-heading-black"
                }
                onClick={() => {
                    setTable(tableType);
                }}
            >
                {tableName}
                {table && table === tableType && <div className="heading-horizontal-line" />}
            </div>
        );
    };
    const onBtExport = () => {
        let rowData = [];
        gridRef.current.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
        const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: table,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(rowData);
    };
    return (
        <section>
            <Header menuItems={getMenuItems()} menuLoading={false} menuLoadingError={false}>
                {pageLoader && <Loader />}
                <CourseSelectDropDown />
                <Paper className={classes.studentDirectoryWrapper}>
                    <div className="header-wrapper">
                        <div className="table-wrapper" style={{ alignItems: "center" }}>
                            {getTableHeaderNames(TABLES.SUBSCRIBED, "Subscribed Users")}
                            {getTableHeaderNames(TABLES.EXPIRED, "Expired Users")}
                            {getTableHeaderNames(TABLES.INACTIVE, "Inactive Users")}
                        </div>
                        <div className="download-and-send-email">
                            <Button
                                onClick={onBtExport}
                                className="download"
                                style={{ color: "#444BAB" }}
                            >
                                <img src={downloadIcon} />
                                Download
                            </Button>
                            {/* Send email feature will come in future */}
                            {/* <Button className="send-email" variant="contained"
                                >
                                    <img src={mailIcon}/>
                                    Send Email
                                </Button> */}
                        </div>
                    </div>
                    <div className="header-wrapper-underline"></div>
                    <div className="count-row">Count:{row}</div>
                    {table && table === TABLES.SUBSCRIBED && getSubscribedUsersGrid()}
                    {table && table === TABLES.EXPIRED && getExpiredUsersGrid()}
                    {table && table === TABLES.INACTIVE && getInactiveUsersGrid()}
                </Paper>
            </Header>
        </section>
    );
}
