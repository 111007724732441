const COURSES = {
    RENAISSANCE: "Renaissance",
    LOW_LEVEL_DESIGN: "Low Level Design",
    DSA_ESSENTIALS: "DSA Essentials",
    FRONTEND_REACT: "Frontend with React",
    CPP: "Cpp",
    PYTHON: "Python",
    JAVA_SPRINGBOOT: "Java Springboot",
};

const COURSE_ID = {
    Renaissance: 1,
    "Low Level Design": 2,
    "DSA Essentials": 6,
    "Frontend with React": 5,
    "Java Springboot": 9,
    Cpp: 3,
    Python: 4,
};
export { COURSES, COURSE_ID };
