const METRICS_TYPE = [
    { id: 1, name: "Points", value: "points" },
    { id: 2, name: "Subscribed users" , value: "subscribed_users"},
    {id: 3, name: "Total Watch Time", value: "total_watch_time"},
    {id:4, name: "Logged In Users", value: "logged_in_users"},
    {id:4, name: "Active Users", value: "active_users"},
    {id:5, name: "Average Points", value: "average_points"},
    {id:6, name: "Average Watch Time", value: "average_watch_time"},
    
];

const TITLE_FOR_CHART = {
    points: "Points",
    subscribed_users: "Subscribed Users",
    total_watch_time: "Total Watch Time",
    logged_in_users:"Logged In Users",
    active_users:"Active Users",
    average_points:"Average Points",
    average_watch_time:"Average Watch Time"
};
const TITLE_CHART_INFO={
    points: "Sum of points gained by all subscribed users on a given day",
    subscribed_users: "Count of users who have a paid subscription and have not expired yet.",
    total_watch_time: "Sum of watch time (in hours) by all subscribed users on a given day",
    logged_in_users:"Total sum of users who logged in to the course atleast once",
    active_users:"Users who have gained atleast 1 point in the last 15 days",
    average_points:"Sum of points gained by all subscribed users on a given day divided by number of users on the same day",
    average_watch_time:"Sum of watch time (in hours) by all subscribed users on a given day divided by number of users on the same day"
};
const DATA_AVERAGE=
{
    average_watch_time:"total_watch_time",
    average_points:"points"
}
const CHART_NAME = {
    "Points": "points" ,
    "Subscribed Users": "subscribed_users",
    "Total Watch Time": "total_watch_time",
    "Logged In Users" :"logged_in_users",
    "Active Users": "active_users",
    "Average Points": "average_points",
    "Average Watch Time": "average_watch_time"  
};
const DAY_PERIOD = {
    DAYS_IN_WEEK: 7,
    DAYS_IN_FORTNIGHT: 14,
    DAYS_IN_MONTH: 30,
    CUSTOM_RANGE:"Custom Range"
};
export {METRICS_TYPE,TITLE_FOR_CHART,TITLE_CHART_INFO,DATA_AVERAGE,DAY_PERIOD,CHART_NAME};