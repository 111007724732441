const LIKELY_STATUS = [
    { status: "Highly likely" },
    { status: "Likely" },
    { status: "Not interested" },
    { status: "Did not connect" },
    { status: "Unlikely" },
];

const CALLERS = [{ name: "Anoop" }, { name: "Mukul" }, { name: "Vartika" }];

export { LIKELY_STATUS, CALLERS};
