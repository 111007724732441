import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { getMenuItems } from "../menuItems/AdminDashboardMenu";
import Header from "../Header/Header";
import { getUsersInfo } from "./SalesDashboardClient";
import Loader from "../Loader/loader";
import LikelinessDropdown from "./LikelinessDropdown";
import SalesDashboardRemarks from "./SalesDashboardRemarks";
import SalesDashboardCaller from "./SalesDashboardCaller";
import "./SalesDashboard.css";
export default function SalesDashboard() {
    const [pageLoader, setPageLoader] = useState(false);

    useEffect(() => {
        getUsersInfo(setRowData, setPageLoader);
    }, []);
    const [rowData, setRowData] = useState([]);
    const DefaultColDef = {
        sortable: true,
        floatingFilter: true,
        filter: true,
    };
    const isNull = (field) => {
        return field == null || field == "" ? "---" : field;
    };

    const [columnDefs] = useState([
        {
            field: "Timestamp",
            pinned: "left",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
        },
        {
            field: "Name",
            pinned: "left",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
        },
        {
            field: "PhoneNumber",
            pinned: "left",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
        },
        {
            field: "Likeliness",
            pinned: "left",
            editable: true,
            cellRenderer: (params) => <LikelinessDropdown params={params} />,
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
        },
        {
            field: "Remarks",
            editable: true,
            cellRenderer: (params) => <SalesDashboardRemarks params={params} />,
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
            width: 600,
        },
        {
            field: "FreeTrial",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
            valueFormatter: (params) => isNull(params.value),
        },
        {
            field: "Subscribed",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
            valueFormatter: (params) => isNull(params.value),
        },
        {
            field: "FreeTrialExpired",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
            valueFormatter: (params) => isNull(params.value),
        },
        {
            field: "SubscriptionExpired",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
            valueFormatter: (params) => isNull(params.value),
        },

        {
            field: "EmailId",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
        },
        {
            field: "College",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            resizable: true,
            valueFormatter: (params) => isNull(params.value),
        },
        {
            field: "GradYear",
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular" },
            valueFormatter: (params) => isNull(params.value),
        },
        {
            field: "CallerName",
            cellRenderer: (params) => <SalesDashboardCaller params={params} />,
            cellStyle: { textAlign: "justify", fontFamily: "Lato-Regular", padding: 0 },
            resizable: true,
        },
    ]);

    return (
        <Header menuItems={getMenuItems()} menuLoading={false} menuLoadingError={false}>
            {pageLoader && <Loader />}
            <div
                className="ag-theme-alpine"
                style={{
                    width: "100%",
                    height: 650,
                    textAlign: "center",
                    "--ag-borders": "none",
                }}
            >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={DefaultColDef}
                ></AgGridReact>
            </div>
        </Header>
    );
}
