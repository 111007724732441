const Course_Id = [
    { text: "Renaissance", value: "1" },
    { text: "LLD", value: "2" },
    { text: "Frontend with React", value: "5" },
    { text: "DSA Essentials", value: "6" },
    { text: "C++", value: "3" },
    { text: "Python", value: "4" },
    { text: "Java SpringBoot", value: "9"}
];

const Discount_Category = [
    { value: "1", text: "Upgrade Discount Code" },
    { value: "2", text: "Subscription Discount Code" },
];

const PLAN_TYPE = {
    UPGRADE: 1,
    SUBSCRIPTION: 2,
};

export { Course_Id, Discount_Category, PLAN_TYPE };
