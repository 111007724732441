import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./StudentDirectoryTable.css"

import { postRemarksDetails } from "./StudentDirectoryClient";

const RemarksButton = (props) => {
    const [remarks, setRemarks] = useState(props.params.value);
    React.useEffect(() => {
        if (remarks) {
            let input = {
                email: props.params.data.EmailID,
                remarks: remarks,
            };
            postRemarksDetails(input);
        }
    }, [remarks]);
    return (
        <>
            {remarks ? (
                <Button style={{color:"#3BABC3"}} >{remarks}</Button>
            ) : (
                <div>
                    <Button style={{color:"#474D54"}}>+Add</Button>
                </div>
            )}
        </>
    );
};

export default RemarksButton;
