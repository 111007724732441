import React, { useState, useEffect, useRef } from "react";
import { Chart as lineChart, ArcElement, Legend, Tooltip } from "chart.js";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import ModalBody from "./ModalBody";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import iIcon from "../../resources/images/i_icon.svg";
import calendar from "../../resources/images/calendar.svg";
import { useGlobalContext } from "../../context/GlobalContext";
import {METRICS_TYPE,TITLE_FOR_CHART,TITLE_CHART_INFO,DAY_PERIOD,} from "./AdminDashboardConstants";
import {getLineGraphData} from "./AdminDashboardClient";
import{getReadableDateMonth} from "../../common/utility";
import "./styles/barChart.css";
lineChart.register(ArcElement, Legend, Tooltip);
const useStyles = makeStyles((theme) => ({
	selectRoot: {
		"&:focus": {
			backgroundColor: "white"
		}
	}
}))

function LineChart(props) {
    const mounted = useRef();
    const classes = useStyles();
    const [myChart, setMyChart] = useState();
    
    const {courseId} = useGlobalContext();

    const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(0, 0, 0, 0)));
    const [startDate, setStartDate] = useState(
        new Date(new Date().setUTCHours(0, 0, 0, 0)).setDate(
            new Date(new Date().setUTCHours(0, 0, 0, 0)).getDate() - 6
        )
    );
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMetrics, setSelectedMetrics] = useState("subscribed_users");
    const [metricsData, setMetricsData] = useState([]);
    const [daysTitle, setDaysTitle] = useState("Past 7 Days");
    const setParams = (metricsDataList, dates) => {
        let scale = window.devicePixelRatio;
        if (!mounted.current) {
            let ctx = document.getElementById("progress");
            setChartValues(ctx, dates, scale,metricsDataList);
            mounted.current = true;
        }
        console.log(metricsDataList, dates);
        setMetricsData(metricsDataList);
        if (myChart) {
            myChart.data.labels = dates;
            myChart.data.datasets[0].data = metricsDataList;
            myChart.options.scales["x"].ticks.font.size = 12 / scale;
            myChart.update();
        }
    };
    
    const hideModal = () => {
        setIsModalVisible(false);
    };

    const setDates = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        hideModal();
    };
    
    const showModal = () => {
        return (
            <Modal open={isModalVisible} onClose={hideModal}>
                <ModalBody
                    setIsMenuVisible={props.setIsMenuVisible}
                    startDate={startDate}
                    endDate={endDate}
                    setDates={setDates}
                    onCancel={hideModal}
                    selectedMetrics={selectedMetrics}
                    setParams={setParams}
                />
            </Modal>
        );
    };
    let CUSTOM_CALENDAR_INDEX = 3;
    const handleMenuClick = (key) => {
        if (key === CUSTOM_CALENDAR_INDEX) {
            setIsModalVisible(true);            
            setDaysTitle(DAY_PERIOD.CUSTOM_RANGE);
        } else {
            let days = [];
            days.push(DAY_PERIOD.DAYS_IN_WEEK - 1);
            days.push(DAY_PERIOD.DAYS_IN_FORTNIGHT - 1);
            days.push(DAY_PERIOD.DAYS_IN_MONTH);
            let endDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
            let startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() - days[key]);
            setStartDate(startDate);
            setEndDate(endDate);
            let daysCount=days[key]+1;
            setDaysTitle("Past "+ daysCount +" days");
            props.setIsMenuVisible(false);
            let start = new Date(startDate);
            let end = new Date(endDate);
            let dates = [];
            while (start < end) {
                dates.push(moment(start).format("D MMM"));
                start.setDate(start.getDate() + 1);
            }
            // getLineGraphData(setParams, selectedMetrics, new Date(startDate), new Date(endDate), dates);
        }
    };
    console.log(startDate, endDate)
    const dropDownOptionDays = ["Last 7 days", "Last 14 days", "Last 31 days", "Custom"];
    const getBarChartMenu = () => {
        return (
            <div className="bar-chart-menu-days-wrapper" onMouseLeave={() => handleMenuClick(0)} onLoad={() => handleMenuClick(0)} >
                {dropDownOptionDays.map((days, key) => {
                    return (
                        <p onClick={() => handleMenuClick(key)} key={key} value={key}>
                            {days}
                        </p>
                    );
                })}
            </div>
        );
    };

    const setChartValues = (ctx, dates, scale,metricsDataList) => {
        lineChart.defaults.font.size = 12 / scale;
        lineChart.defaults.borderColor = "black";
        lineChart.defaults.color = "black";
        setMyChart(
            new lineChart(ctx, {
                type: "bar",
                data: {
                    labels: dates,
                    datasets: [
                        {
                            type: "line",
                            label: "",
                            borderColor: "#0F5CB2",
                            backgroundColor: "#0F5CB2",
                            pointBackgroundColor: "#0F5CB2",
                            pointRadius: 1.5 / scale,
                            borderWidth: 1.25 / scale,
                            data: metricsDataList,
                            order: 1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    scales: {
                        x: {
                            grid: {
                                color: "white",
                                tickLength: 5 / scale,
                                borderWidth: 0.8 / scale,
                            },
                            ticks: {
                                font: {
                                    size: 12 / scale,
                                    align: "start",
                                },
                                autoSkip: true,
                                maxRotation: 0,
                                minRotation: 0,
                                autoSkipPadding: 10 / scale,
                                padding: 5 / scale,
                            },
                        },
                        y: {
                            grid: {
                                color: "white",
                                tickColor: "black",
                                tickLength: 5 / scale,
                                borderWidth: 0.5 / scale,
                                tickWidth: 0.8 / scale,
                            },
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                                padding: 5 / scale,
                            },
                        },
                    },
                    plugins: {
                        tooltip: {
                            displayColors:false,
                            titleColor:'#343A40',
                            backgroundColor:'#F3F3F3',
                            titleFont: {
                                size: 15,
                                weight: 400
                              },
                            bodyFont: {
                                size: 14
                            },
                            callbacks: {
                                label:(context)=>{
                                    const arrayLine = [
                                        `${TITLE_FOR_CHART[localStorage.metricInLabel]} : ${context.raw}`,
                                      ];
                                      return arrayLine;
                                },
                                labelTextColor: function(context) {
                                    return '#343A40';
                                }
                            }
                        },
                        legend: {
                            display: false,
                            align: "end",
                            labels: {
                                padding: 5 / scale,
                            },
                        },
                    },
                },
            })
        )
    };

    useEffect(() => {
        localStorage.setItem('metricInLabel', selectedMetrics) ;
        if (!mounted.current) {
            let start = new Date(startDate);
            let end = new Date(endDate);
            let dates = [];
            while (start < end) {
                dates.push(moment(start).format("D MMM"));
                start.setDate(start.getDate() + 1);
            }
            getLineGraphData(setParams, selectedMetrics, new Date(startDate), new Date(endDate), dates, courseId);
        } else {
            let start = new Date(startDate);
            let end = new Date(endDate);
            let dates = [];
            while (start < end) {
                dates.push(moment(start).format("D MMM"));
                start.setDate(start.getDate() + 1);
            }
            getLineGraphData(setParams, selectedMetrics, new Date(startDate), new Date(endDate), dates, courseId);
        }
    },[courseId,startDate, endDate, selectedMetrics]);
    
    const handleSelectClick = () => {
        props.setIsMenuVisible(!props.isMenuVisible);
    };

    const getDropdownDateToDisplay = () => {
        return (
            <div
                className="bar-chart-select-wrapper"
                onClick={handleSelectClick}
            >
                <img src={calendar} className="calendar-size"/>
                <b>{daysTitle}</b>
                <KeyboardArrowDownIcon className="down-arrow" />
                <p>
                    <b>{getReadableDateMonth(moment(startDate))}</b> to <b>{getReadableDateMonth(moment(endDate))}</b>
                </p>
                
            </div>
        );
    };

    const getDateDropDown = () => {
        return (
            <div>
                <div className="chart-box">
                    {getDropdownDateToDisplay()}
                    {props.isMenuVisible && getBarChartMenu()}
                </div>
            </div>
        );
    };

    const getGraph = () => {
        return (
            <div>
                <div className="line-chart-axis-title">
                    <br />
                    {TITLE_FOR_CHART[selectedMetrics]}
                </div>
                <canvas id="progress" height="100px" width="200px" />
            </div>
        );
    };
    const userSelectMetrics = (event) => {  
        let value = event.target.value;   
        setSelectedMetrics(value);
    };

    const getDropdownMetrics = () => {
        return (
            <div className=" ml-2 progress-chart-heading">
                <FormControl size="small">
                    <Select
                        Select classes={{ root: classes.selectRoot }}
                        value={selectedMetrics}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        onChange={userSelectMetrics}
                        placeholder="status"
                        MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                          disableUnderline >
                        {METRICS_TYPE.map((type) => (
                            <MenuItem id={type.id} value={type.value}>
                                <div className="dropdown-text">
                                    {type.name}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
            </FormControl>
            <div class="i-button-tooltip"><img src={iIcon} className="i-button"/>
                <span class="tooltiptext">{TITLE_CHART_INFO[selectedMetrics]}</span>
            </div>
            
        </div>
        );
    }
    return (
      <div>
        <div>{showModal()}</div>
        <div className="linechart-dropdown-metrics">
          <div> 
            {getDropdownMetrics()}
          </div>   
        <div className="linechart-dropdown-date">{getDateDropDown()}</div>
        </div>
        {getGraph()}
      </div>
    );
}

export default LineChart;
