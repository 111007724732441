import React, { useEffect, useState } from "react";
import "./App.css";
import "./fonts.css";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AdminDashboard from "./Components/AdminDashboard/AdminDashboard";
import Login from "./Components/Login/Login";
import StudentDirectory from "./Components/StudentDirectory/StudentDirectory";
import ExtendSubscription from "./Components/ExtendSubscription/ExtendSubscription";
import DiscountCoupon from "./Components/DiscountCoupon/DiscountCoupon";
import SalesDashboard from "./Components/SalesDashboard/SalesDashboard"
import { GlobalContextProvider } from "./context/GlobalContext";
function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>
                <Route path="/home">
                    <GlobalContextProvider>
                        <AdminDashboard />
                    </GlobalContextProvider>
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/studentDirectory">
                    <GlobalContextProvider>
                        <StudentDirectory />
                    </GlobalContextProvider>
                </Route>
                <Route path="/extendSubscription">
                    <ExtendSubscription />
                </Route>
                <Route path="/discountCoupon">
                    <DiscountCoupon />
                </Route>
                <Route path="/salesDashboard">
                    <SalesDashboard />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
