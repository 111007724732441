import axios from "axios";
import Cookies from "universal-cookie";
import { initiateLogout } from "../../common/utility";
import { STATUS_CODES } from "../../common/constants";

const getSubscribedUsers = (courseId,setSubscribedUsers) => {
    const cookies = new Cookies();
    let input = {
        Course_Id: courseId
    }
    return axios
        .post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/studentDirectory/getSubscribedUsersList",input, {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization")
            },
        })
        .then((Response) => {
            if (Response && Response.data) setSubscribedUsers(Response.data);
        })
        .catch((error) => {
            console.log("error is ", error);
            if (
                error &&
                error.response &&
                error.response.status &&
                error.response.status === STATUS_CODES.HTTP_UNAUTHORIZED
            ) {
                initiateLogout();
            }
        });
};
const getExpiredUsers = (courseId,setExpiredUsers) => {
    const cookies = new Cookies();
    let input = {
        Course_Id: courseId
    }
    return axios
        .post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/studentDirectory/getExpiredUsersList",input, {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization")
            },
        })
        .then((Response) => {
            if (Response && Response.data) setExpiredUsers(Response.data);
        })
        .catch((error) => {
            console.log("error is ", error);
            if (
                error &&
                error.response &&
                error.response.status &&
                error.response.status === STATUS_CODES.HTTP_UNAUTHORIZED
            ) {
                initiateLogout();
            }
        });
};
const getInactiveUsers = (courseId, setInactiveUsers) => {
    const cookies = new Cookies();
    let input = {
        Course_Id: courseId
    }
    return axios
        .post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/studentDirectory/getInactiveUsersList",input,
         {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization")
            },
        })
        .then((Response) => {
            if (Response && Response.data) setInactiveUsers(Response.data);
        })
        .catch((error) => {
            console.log("error is ", error);
            if (
                error &&
                error.response &&
                error.response.status &&
                error.response.status === STATUS_CODES.HTTP_UNAUTHORIZED
            ) {
                initiateLogout();
            }
        });
};

const postBatchDetails = (data) => {
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/studentDirectory/postBatchDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((Response) => {
            console.log("response is ", Response);
        })
        .catch((error) => {
            console.log("error is ", error);
        });
};
const postPlacementDetails = (data) => {
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/studentDirectory/postPlacementDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((Response) => {
            console.log("response is ", Response);
        })
        .catch((error) => {
            console.log("error is ", error);
        });
};
const postRemarksDetails = (data) => {
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/studentDirectory/postRemarksDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((Response) => {
            console.log("response is ", Response);
        })
        .catch((error) => {
            console.log("error is ", error);
        });
};
export {
    getSubscribedUsers,
    getExpiredUsers,
    getInactiveUsers,
    postBatchDetails,
    postPlacementDetails,
    postRemarksDetails,
};
