import React,{useContext, useState} from "react";


const GlobalContext = React.createContext();

export function useGlobalContext(){
    return useContext(GlobalContext);
}

export function GlobalContextProvider({children}){
    const [courseId,setCourseId] = useState(1);
    const [pageLoader,setPageLoader] = useState(false);


    const value = {
        courseId,
        setCourseId,
        pageLoader,
        setPageLoader
    }

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}