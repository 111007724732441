import Cookies from "universal-cookie";
let setAuthorizationCookie = (token) => {
    const cookies = new Cookies();
    if (cookies.get("authorization")){
        cookies.remove("authorization", {
            path: "/",
            domain: ".programmingpathshala.com",
        });
        //uncomment to run locally
        //cookies.remove("authorization");
    }
   
    cookies.set("authorization", token, {
        path: "/",
        domain: "admin.programmingpathshala.com",
    });

    //uncomment to run locally
    //cookies.set("authorization", token);
};

export { setAuthorizationCookie };
