import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Cookies from "universal-cookie";
import "./Header.css";
import ppaIcon from "../../resources/images/ppaIcon.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import menuIconOpen from "../../resources/images/sideNavOpen.svg";
import menuIconClose from "../../resources/images/sideNavClose.svg";

function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

const TooltipWithCustomStyle = withStyles({
    tooltip: {
        color: "#343A40",
        backgroundColor: "#F3F3F3",
        borderRadius: "0.2rem",
        fontFamily: "Gilroy-Medium",
        fontSize: "1rem",
        margin: "0",
    },
})(Tooltip);

export default function Header(props) {
    const location = useLocation();    
    const [openBar, setOpenBar] = useState(false);
    const handleDrawerOpen = () => {
        setOpenBar(true);
    };
    const handleDrawerClose = () => {
        setOpenBar(false);
    };
    const [logout, setLogout] = useState(false);
    const appBarHeight = "4.6rem";
    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
        },
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            height: appBarHeight,
        },
        menuButton: {
            color: "#888888",
        },
        hide: {
            display: "none",
        },
        drawer: {
            flexShrink: 0,
        },
        drawerPaper: {
            paddingTop: appBarHeight,
        },
        drawerPaperS: {
            paddingTop: "20px",
            zIndex: "10000",
        },
        drawerPaperLarge: {
            paddingTop: appBarHeight,
            width: "18vw",
        },
        drawerPapersmall: {
            paddingTop: appBarHeight,
            width: 280,
        },
        drawerPaperShiftLarge: {
            paddingTop: appBarHeight,
            width: "80px",
            border: "1px solid #F3F3F3",
            overflowX: "hidden",
        },
        drawerPaperShiftS: {
            width: 0,
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentMargin: {
            marginLeft: 80,
            width: "calc(100vw - 80px)",
        },
        contentMarginS: {
            marginLeft: 0,
            width: "100vw",
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        contentShiftMarginL: {
            marginLeft: "18vw",
            width: "82vw",
        },
        contentShiftMarginM: {
            marginLeft: 280,
            width: "calc(100vw - 280px)",
        },
        menuItemActive: {
            backgroundColor: "#EBE8FF !important",
            color: "#4F4F4F",
            marginLeft: "5px",
            width: "auto",
            minHeight: "8vh",
            height: "fit-content",
        },
        homeMenuItemActive: {
            color: "#444BAB",
            fontFamily: "Poppins-SemiBold",
            fontSize: "0.95vw",
            paddingLeft: "0.5vw"
        },
        topicMenuItemActive: {
            color: "#4F4F4F",
            marginLeft: "5px",
            minHeight: "8vh",
            height: "fit-content",
            width: "auto",
        },
        menuItemDefault: {
            minHeight: "6vh",
            height: "fit-content",
            color: "#4F4F4F",
        },
        menuImgActive: {
            filter: "invert(2%) sepia(14%) saturate(4538%) hue-rotate(207deg) brightness(84%) contrast(101%)",
        },
        snackbarButton: {
            textTransform: "none",
            fontFamily: "Poppins-SemiBold",
        },
        itemText: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "0.95vw",
            color: "#4F4F4F",
            paddingLeft: "0.5vw"
        }
    }));

    const { menuItems, menuLoading, menuLoadingError } = props;
    const classes = useStyles();
    const history = useHistory();
    const currentWindowsWidth = useWindowDimensions();
    const initiateMenuAction = (action, path, itemName) => {
            history.push(path)
    };

    const getItemIcon = (item) => {
            return (
                <img
                    className={
                        location.pathname + location.search === item.path
                            ? classes.menuImgActive
                            : classes.menuImg
                    }
                    src={item.icon}
                />
            );
    };

    const getIconWithTooltipWrap = (item) => {
        if (openBar) {
            return <ListItemIcon style={{ marginLeft: "20px" }}>{getItemIcon(item)}</ListItemIcon>;
        }
        return (
            <TooltipWithCustomStyle
                title={`${
                    item.text
                }`}
                placement="left-start"
            >
                <ListItemIcon style={{ marginLeft: "28px" }}>{getItemIcon(item)}</ListItemIcon>
            </TooltipWithCustomStyle>
        );
    };


    const drawer = (
        <div>
            <div className={classes.toolbar} />
            {menuLoading ? (
                <p>Loading</p>
            ) : menuLoadingError ? (
                <p>Error loading menu items</p>
            ) : (
                <List style={{ paddingTop: "0px" }}>
                    {menuItems?.map(
                        (item, index) =>
                            item.text !== "My Profile" && (
                                <div key={index}>
                                    <Divider style={{opacity:"0.25"}} />
                                    <div
                                        style={{
                                            backgroundImage: "linear-gradient(#3BABC3, #444BAB)",
                                        }}
                                    >
                                        <ListItem
                                            button
                                            key={item.text}
                                            onClick={() =>
                                                initiateMenuAction(item.action, item.path)
                                            }
                                            style={{ backgroundColor: "white",justifyContent:"center" }}
                                        >
                                            {getIconWithTooltipWrap(item)}
                                            <ListItemText
                                                primary={
                                                    <div
                                                    className={location.pathname + location.search === item.path
                                                        ? classes.homeMenuItemActive
                                                        : classes.itemText}
                                                    >
                                                        {item.text}
                                                    </div>
                                                }
                                                className={openBar ? "nav-list-item" : classes.hide}
                                            />
                                        </ListItem>
                                    </div>
                                </div>
                            )
                    )}
                </List>
            )}
        </div>
    );

   

    const goToProfile = () => {
        history.push("/home");
    };

    const handleLogout= ()=>{
        const cookies = new Cookies();
        if (cookies.get("authorization")){
            cookies.remove("authorization", {
                path: "/",
                domain: "admin.programmingpathshala.com",
            });
        }
        //ENV VAR ADDED
        window.openBar(process.env.REACT_APP_FRONTEND_ADMIN_URL+"/login", "_self");
    }
    const getLogoutButton =()=>{
        return (
            <div className="logout-wrapper">
                <button onClick={handleLogout}>Logout</button>
            </div>
        )
    } 
        
        
    
   

    let marginTop = appBarHeight;
    return (
        <div className={classes.root}>
            <CssBaseline />
                <AppBar position="fixed" className={`headerAppBar ${classes.appBar}`}>
                    <Toolbar style={{ height: "100%" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            className={clsx(
                                currentWindowsWidth <= 500 ? classes.menuButton : classes.hide
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            style={{ marginLeft: "0.5vw" }}
                            className="link-clickable-pointer-icon"
                            src={ppaIcon}
                           
                        ></img>
                        <Typography variant="h4" noWrap style={{ flexGrow: "1" }}>
                            <h5
                                className="headerHeading link-clickable-pointer-icon"
                                
                            >
                                Programming Pathshala
                            </h5>
                        </Typography>
                       
                            <AccountCircleIcon
                                fontSize="large"
                                style={{
                                    color: "#444BAB",
                                    opacity: "0.8",
                                    marginRight: "5px",
                                    cursor: "pointer",
                                }}
                                onClick={goToProfile}
                            />
                        <Typography variant="h6">
                            <h6 className="userName" onClick={()=>{setLogout(true)}}>
                               User Name
                               {logout && getLogoutButton()}
                            </h6>
                        </Typography>
                    </Toolbar>
                </AppBar>
            <div>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={true}
                    classes={{
                        paper: openBar
                            ? currentWindowsWidth <= 500
                                ? classes.drawerPaperS
                                : currentWindowsWidth <= 1200
                                ? classes.drawerPapersmall
                                : classes.drawerPaperLarge
                            : currentWindowsWidth <= 500
                            ? classes.drawerPaperShiftS
                            : classes.drawerPaperShiftLarge,
                    }}
                >
                    <div>
                        <MenuItem
                            onClick={openBar ? handleDrawerClose : handleDrawerOpen}
                            className={classes.drawerHeader}
                        >
                            <ListItemIcon style={{ marginLeft: "20px",paddingTop:"1vw",paddingBottom:"1vw" }}>
                                {openBar ? <img src={menuIconOpen} /> : <img src={menuIconClose} />}
                            </ListItemIcon>
                            <ListItemText
                                className={openBar ? "option-text ListItemText" : classes.hide}
                            >
                                <span className="list-item-menu-text">COLLAPSE MAIN MENU</span>
                            </ListItemText>
                        </MenuItem>
                    </div>                    
                    {drawer}
                    
                </Drawer>
            </div>
            <main
                className={clsx(
                    classes.content,
                    openBar
                        ? currentWindowsWidth <= 1200
                            ? currentWindowsWidth <= 850
                                ? currentWindowsWidth <= 500
                                    ? classes.contentMarginS
                                    : classes.contentMargin
                                : classes.contentShiftMarginM
                            : classes.contentShiftMarginL
                        : currentWindowsWidth <= 500
                        ? classes.contentMarginS
                        : classes.contentMargin,
                    {
                        [classes.contentShift]: openBar,
                    }
                )}
                style={{ padding: "0px" }}
            >
                <div style={{ marginTop: marginTop }}>{props.children}</div>
            </main>

        </div>
    );
}
