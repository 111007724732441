import axios from "axios";
import Cookies from "universal-cookie";

const postSubscriptionDiscountCoupon = (
    data,
    setSnackBarOpen,
    setSnackBarClose,
    setLoading,
    setError
) => {
    const cookies = new Cookies();
    setLoading(true);
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL +
                "/discountCoupon/postSubscriptionDiscountCoupon",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((Response) => {
            if (Response.status === 200) {
                setSnackBarOpen(true);
                setLoading(false);
            }
        })
        .catch((error) => {
            setLoading(false);
            setSnackBarClose(true);
            if (error.response.data.error === "discount greater than 50%") {
                setError("Discount can't be greater than 50%");
            } else {
                setError("Oops! Something Went Wrong");
            }
        });
};

const postUpgradeDiscountCoupon = (
    data,
    setSnackBarOpen,
    setSnackBarClose,
    setLoading,
    setError
) => {
    const cookies = new Cookies();
    setLoading(true);
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/discountCoupon/postUpgradeDiscountCoupon",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((Response) => {
            if (Response.status === 200) {
                setSnackBarOpen(true);
                setLoading(false);
            }
        })
        .catch((error) => {
            setLoading(false);
            setSnackBarClose(true);
            if (error.response.data.error === "discount greater than 50%") {
                setError("Discount can't be greater than 50%");
            } else {
                setError("Oops! Something Went Wrong");
            }
        });
};

const getPlanDetails = (
    data,
    setPlanDetails,
    setDiscountAmountDetails,
    setSnackBarClose,
    setLoading,
    setError
) => {
    const cookies = new Cookies();
    setLoading(true);
    axios
        .post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/discountCoupon/getPlanDetails", data, {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            if (response && response.data) {
                setDiscountAmountDetails(new Array(response.data.length).fill(0));
                setPlanDetails(response.data);
            }

            setLoading(false);
        })
        .catch((error) => {
            setError("Oops! Something Went Wrong");
            setSnackBarClose(true);
            setLoading(false);
        });
};

export { postSubscriptionDiscountCoupon, postUpgradeDiscountCoupon, getPlanDetails };
